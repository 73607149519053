import { useNavigate } from "react-router-dom";
import { Film } from "../../Data/films";
import FilmCard from "../Submission/FilmCard";
import { useState } from "react";

interface NominationSectionProps {
    title: string;
    nominationList: Film[];
}

const NominationSection = ({...props}: NominationSectionProps) => {
    const [hoveredFilm, setHoveredFilm] = useState<Film | null>(null);
    const navigate = useNavigate();

    const handleFilmHover = (film : Film) => {
        setHoveredFilm(film);
    }

    const handleMouseLeave = () => {
        setHoveredFilm(null);
    }

    const handleFilmClick = (film : Film) => {
        navigate('/synopsis', { state: { film } });
    }

    const fadeInAnimationVariants= {
        initial:{
            opacity:0,
            y:100,
        },
        animate: {
            opacity:1,
            y:0,
        }
    }
    
    return (
        <div>
            <h2 className="text-5xl text-light-gold hover:shadow-lg hover:shadow-light-gold rounded-lg p-4 font-bold w-96 ml-64">
                {props.title}
            </h2>
            <div className="flex flex-wrap justify-center">
                {props.nominationList.map((film, index) => (          
                    <FilmCard
                        handleFilmClick={handleFilmClick}
                        handleMouseLeave={handleMouseLeave}
                        handleFilmHover={handleFilmHover}
                        fadeInAnimationVariants={fadeInAnimationVariants}
                        hoveredFilm={hoveredFilm}
                        film={film} index={index}
                    />       
                ))}
            </div>
        </div>
          
    )
}

export default NominationSection;