import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Navbar.css";
// import IFFAlogo from "../../images/IFFA_logo_2024_Logo Gold (1).png";
import { IFFALogo } from "../../Data/export_images";
import Navlink from "./Navlink";

const Navbar = ({isHomePage = false} : {isHomePage?: boolean}) => {
    const [isOpen, setIsOpen] = useState(false);
    const submissionLink = "https://filmfreeway.com/iffa-awards?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabJeQpWqtNQoaQyng5NgUKY924NJ4lZXs8zxwIkFKDRGb-A8_Q4MsSGpGg_aem_rRIoYWLW6NfghTFaAgz4Zw";

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const closeDropdown = (e: MouseEvent) => {
            if(e.composedPath()[0] !== menuRef.current && e.composedPath()[0] !== buttonRef.current) {
                setIsOpen(false);
                console.log("clicked outside")
                console.log(buttonRef.current)
                console.log(e.composedPath()[0])
                console.log(menuRef.current)
            }
        }

        document.body.addEventListener('click', closeDropdown);

        return () => {
            document.body.removeEventListener('click', closeDropdown);
        }
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, y:-150 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
            className={`flex justify-between items-center w-full h-fit p-4 max-md:px-20 md:px-40 py-10 max-lg:mb-10 max-lg:h-[12vh] ${isHomePage ? "bg-transparent absolute max-md:top-0 z-20" : ""}`}

        >
            <a href="./" className="mr-10">
                <img className="h-[1vw] max-w-12 w-[5vw] min-w-24 min-h-16 max-lg:h-[4vh]" id="logo" src={IFFALogo} alt="IFFA Logo" />
            </a>
            <div className="lg:hidden block max-lg:relative top-0 right-0 text-[2rem] text-white cursor-pointer" ref={buttonRef}  onClick={() => setIsOpen(prev => !prev)}>
                &#9776; {/* Menu icon */}
            </div>
            <div className={`max-lg:absolute z-20 top-36 right-40 max-md:right-20 flex flex-wrap gap-y-4 max-lg:bg-white max-lg:p-4 max-lg:rounded-lg  ${isOpen ? "max-lg:flex-col" : "max-lg:hidden"}`} ref={menuRef} >
                <Navlink setIsOpen={setIsOpen} link="/submission" text="FILM LIST" />
                <Navlink setIsOpen={setIsOpen} link="/nomination" text="NOMINATION" />
                <Navlink setIsOpen={setIsOpen} link="/about" text="ABOUT" />
                <Navlink setIsOpen={setIsOpen} link="/contact" text="CONTACT" />
                <Navlink setIsOpen={setIsOpen} link="/partners" text="PARTNERS" />
                <Navlink setIsOpen={setIsOpen} link={submissionLink} text="SUBMIT YOUR WORK" isSubmit></Navlink>
            </div>
        </motion.div>
    );
}

export default Navbar;



