import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { MonkeyManTrailerCropped } from "../../Data/export_videos"
import { TitleSVG } from "../../Data/export_images"
import Navbar from "../Navbar/Navbar"

const MobileHeroSection = () => {
    return (
        <div className="relative flex flex-col py-10 gap-y-10 justify-end items-center md:hidden h-[475px]">
            <Navbar isHomePage/>

            <video
                autoPlay
                loop
                muted
                className="absolute top-0 w-full h-[500px] max-xl:object-fill"
            >
                <source src={MonkeyManTrailerCropped} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='absolute h-[500px] top-0 inset-0 bg-black opacity-50'/>
            <motion.img
                src={TitleSVG}
                alt="Title"
                initial="hidden"
                animate="visible"
                // variants={floatVariants}
                whileHover={{ scale: 1.1 }}
                style={{ willChange: "transform" }} // Ensure smooth rendering
                className="w-[250px]"
            />
            <div className="z-10 flex flex-col items-center text-white text-3xl font-bold">
                <h1>5 November 2024</h1>
            </div>
            <motion.div
                initial="hidden"
                animate="visible"
                // variants={floatVariants}
                whileHover={{ scale: 1.15 }}
                transition={{ type: 'spring', stiffness: 300 }}
                className="flex items-center justify-center text-white w-fit px-5 xl:w-64 border-2 border-white font-bold text-center py-3 xl:py-6 transition-all duration-500 ease-in-out rounded-xl"
                style={{ willChange: "transform" }} // Ensure smooth rendering
                // className
            >
                <Link to="https://www.ticketmaster.com.au/international-film-festival-tickets/artist/1347704" className="text-xl">Get Tickets</Link>
            </motion.div>
        </div>
    )
}

export default MobileHeroSection;