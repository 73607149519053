// import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
// import FilmCard from "../Components/Submission/FilmCard";
import { 
    animationNominations,
    documentaryNominations,
    featureFilmNominations,
    shortFilmNominations,
    under18Nominations
} from "../Data/films";
// import { useState } from "react";
// import { hover } from "@testing-library/user-event/dist/hover";
import NominationSection from "../Components/Nominations/NominationSection";


const Nominations = () => {
    return (
        <section className="bg-black">
            <Navbar />
            <main className="flex flex-col items-center mt-10 mb-20 text-white">
                <h1 className="text-white text-5xl uppercase font-bold">Nominations 2024</h1>
                <div className="flex flex-col mt-20 gap-y-20">
                    <NominationSection title="Feature Films" nominationList={featureFilmNominations} />
                    <NominationSection title="Animations" nominationList={animationNominations} />
                    <NominationSection title="Documentary" nominationList={documentaryNominations} />
                    <NominationSection title="Short Films" nominationList={shortFilmNominations} />
                    <NominationSection title="Under 18s" nominationList={under18Nominations} />
                </div>    
                
            </main>
            <Footer />
        </section>
    );
};

export default Nominations;