import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Header from '../Components/Header/Header';
import '../CSS/styles.css';
import '../CSS/home.css'
import Footer from '../Components/Footer/Footer';
import MissionSection from '../Components/Missions/Missions';
import HeroSection from '../Components/frontpage/HeroSection';
import Submissions from '../Components/SubmissionsHome/SubmissionsHome';
import { MonkeyManTrailer } from '../Data/export_videos';
import Navbar from '../Components/Navbar/Navbar';
// import Frontpage2 from '../Components/frontpage/Frontpage2';
function Home() {

  return (
    <div className='landingpage relative'>
      {/* <Navbar /> */}
      <HeroSection></HeroSection>
      <MissionSection></MissionSection>
      <Submissions></Submissions>
      <Footer />
    </div>
  );
}

export default Home;
